<template>
    <div class="footer">
        <h5>핀모든</h5>
        <ul class="info">
            <li>대표이사 : 용현봉</li>
            <li>서울 강남구 테헤란로 313 (역삼동, 성지하이츠1) 11층 1118호</li>
            <li>사업자등록번호 : 794-81-02780</li>
            <li>불편사항 접수 : 02-501-0214</li>
            <li>help@finmodeun.com</li>
            <li><a href="#" title="#">대출성상품금융상품판매대리중개업 [등록증 확인하기]</a></li>
        </ul>
        <ul class="terms">
            <li><a href="#" title="" @click="termsDetail('1', '1')">서비스이용약관</a></li>
            <li><a href="#" title="" @click="termsDetail('1', '2')">개인정보처리방침</a></li>
            <li><a href="#" title="" @click="termsDetail('5', '15')">대출성금융상품판매대리중개업자 관련 고지사항</a></li>
            <li><a href="#" title="" @click="termsDetail('5', '5')">이용자고충처리방침</a></li>
        </ul>
        <div>
            <p>
                본 서비스는 회사와 서비스에 관한 위탁 계약을 체결한 제휴 금융회사 등의 대출 상품에 한하여 제공되는 것이며, 모든 금융회사의 전체 대출 상품을 대상으로 하지 않습니다.<br>
                핀모든은 대출계약을 체결할 권한이 없으며, 여신심사등을 통한 대출계약체결권은 금융상품직접판매업자인 금융회사에게 있습니다.<br>
                본 서비스 이용과 관련하여 소비자는 금융소비자보호법 제44조와 제45조에 따라 금융상품직접판매업자인 금융기관 및 금융상품 판매대리중개업자인 핀모든에서 손해배상을 청구할 수 있습니다.<br>
                본 서비스 이용을 위해 소비자가 입력한 개인정보와 신용정보는 금융상품직접판매업자인 금융회사가 보유 및 관리합니다.<br>
                핀모든은 제휴금융회사에서 제공받은 금융 상품 정보를 전달하고 있습니다. <br>
                핀모든에서 조회되는 대출가능 한도/금리가 달라지거나 대출이 거절되는 등 가심사 결과와 달라질 수 있습니다.<br>
                핀모든에서 제공하는 금융 정보는 참고사항이며, 제공된 정보에 대한 법적인 책임을 지지 않습니다.<br>
                금융소비자가 대출계약 체결을 위해 금융회사 앱 또는 웹페이지를 통해 대출 실행을 진행함에 있어 핀모든은 어떠한 관여도 하지 아니합니다.<br>
                본 서비스를 통한 대출계약, 대출실행 등과 관련해 소비자로부터 어떠한 금전적 대가도 받지 않으며, 금융회사를 대신하여 대출이자, 원금상환 등을 받지 않습니다.<br>
                금융회사에서 지급하고 있는 대출모집수수료율 기준은 개별 금융회사의 홈페이지에서 확인 가능합니다.
            </p>
            <p>
                대출상품 이용 시 귀하의 개인신용평점이 하락할 수 있습니다.<br>개인신용평점이 하락하면 불이익이 발생할 수 있습니다.<br>대출모집법인 포털사이트 (대출모집인 포털사이트), 저축은행중앙회(02-397-8600)에서 신원확인 가능합니다.
            </p>
        </div>
    </div>
</template>

<script setup>
import {defineEmits, defineProps, reactive} from "vue";

const props = defineProps({
    call_page: String
});
const emits = defineEmits(['loadContents', 'back']);

let pageModel = reactive({});

async function termsDetail(_termsTarget, _termsType) {
    let param = {
        terms_target: _termsTarget
        , terms_type: _termsType
        , call_page: props.call_page
    };

    emits('loadContents', '/common/TermDetail.vue', param);
}
</script>
