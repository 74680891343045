import { defineStore } from "pinia";

export const useCustomerStore = defineStore('customer_store', {
    state: () => ({
        accessToken: '',
        customerInfo: null,
        kakaoInfo: null,
        pageHist: []
    })
    , getters: {}
    , actions: {}
    , persist: {
        storage: sessionStorage
    }
});
