<template>
</template>
<script setup>
import {defineProps, onMounted} from "vue";
import {$client, $dialog, $kcb_cert, $utils} from "@/assets/js/utils";
import {useCustomerStore} from "@/stores/customerStore";
import axios from "axios";

const props = defineProps({
    param: Object
});

onMounted(() => {
    init();
})

async function init() {
    let kakaoParam = {
        grant_type: "authorization_code"
        , client_id: "9f6a383e555bfd060692a7b7cb6963ba" // REST API 키
        , redirect_uri: $utils.getUrl() + "/kakao/login"
        , code: props.param.code
    };

    const queryString = Object.keys(kakaoParam)
            .map((k) => k + "=" + encodeURIComponent(kakaoParam[k]))
            .join("&");

    await axios.post(
            "https://kauth.kakao.com/oauth/token"
            , queryString
            , {
                headers: {
                    "Content-type": "application/x-www-form-urlencoded;charset=utf-8"
                },
            }
    ).then(response => {
        sessionStorage.setItem('kakao_token', response.data.access_token);

        window.Kakao.Auth.setAccessToken(response.data.access_token);
    }).catch(error => {
        $dialog.alert(error.response.data.error_description, function() {
            window.location.href = '/login';
        });
    });

    // 본인 정보 조회
    if ($utils.isNotBlank(sessionStorage.getItem('kakao_token'))) {
        await Kakao.API.request({
            url: '/v2/user/me'
        }).then(response => {
            sessionStorage.removeItem('kakao_token');

            sessionStorage.setItem('kakao_id', response.id);
            sessionStorage.setItem('kakao_email', response.kakao_account.email);
        }).catch(error => {
            sessionStorage.removeItem('kakao_token');

            $dialog.alert(error.msg, function() {
                window.location.href = '/login';
            });
        });
    }

    // 로그인 처리
    if ($utils.isNotBlank(sessionStorage.getItem('kakao_id'))) {
        kakaoParam = {
            kakao_id: sessionStorage.getItem('kakao_id')
            , kakao_email: sessionStorage.getItem('kakao_email')
        };

        await $client.postDirect('/svc/kakao/login', kakaoParam).then(response => {
            useCustomerStore().accessToken = response.data.data.access_token;
            useCustomerStore().customerInfo = response.data.data.customer_info;

            sessionStorage.removeItem('kakao_id');
            sessionStorage.removeItem('kakao_email');

            window.location.href = '/';
        }).catch(error => {
            if (error.response.data.res_code === '636') {
                $dialog.alert(error.response.data.res_msg + '<br/>회원가입으로 이동합니다.', function() {
                    window.location.href = '/join';
                });
            } else {
                $dialog.alert(error.response.data.res_msg, function() {
                    window.location.href = '/login';
                });
            }
        });
    }
}
</script>
