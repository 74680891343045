<template>
    <component
            :is="mainComponent"
            :mainParam="mainParam"
            @loadContents="loadContents"
            @back="back"
            @home="home"/>
</template>
<script setup>
import {defineAsyncComponent, defineProps, markRaw, onMounted, ref} from "vue";
import {$log, $utils} from "@/assets/js/utils";
import {useCustomerStore} from "@/stores/customerStore";

const props = defineProps({
    param: Object
});

let mainComponent = ref();
let mainParam = ref();

onMounted(() => {
    init();
})

function init() {
    $log.debug('', 'host.url', $utils.getUrl());

    let _type = props.param.type;

    if ($utils.isNotBlank(sessionStorage.getItem('kcb_cert_call'))) {
        _type = sessionStorage.getItem('kcb_cert_call');
    }

    $log.debug('', '_type', _type);

    sessionStorage.removeItem('kcb_cert_call');

    if (_type === 'LOGIN') {
        loadContents('/Login.vue');
    } else if (_type === 'JOIN') {
        if ($utils.isBlank(sessionStorage.getItem('tx_seq_no'))) {
            loadContents('/join/JOIN00.vue');
        } else {
            loadContents('/join/JOIN10.vue');
        }
    } else if (_type === 'MAIN' || _type === 'CREDIT_ERROR') {
        loadContents('/loan/LoanMain.vue', { type: _type });
    } else if (_type === 'CREDIT') {
        if ($utils.isBlank(sessionStorage.getItem('tx_seq_no'))) {
            loadContents('/loan/LoanMain.vue', { type: 'MAIN' });
        } else {
            loadContents('/loan/credit/LCC001.vue');
        }
    } else if (_type === 'MORTGAGE') {
        loadContents('/loan/mortgage/LMR001.vue');
    } else if (_type === 'MORTGAGE_ERROR') {
        loadContents('/loan/mortgage/LMC006.vue');
    } else if (_type === 'JEONSE') {
        loadContents('/loan/jeonse/LJR001.vue');
    } else if (_type === 'JEONSE_ERROR') {
        loadContents('/loan/jeonse/LJC006.vue');
    } else if (_type === 'FIND') {
        loadContents('/customer/FindCustomer.vue');
    } else if (_type === 'CHG') {
        loadContents('/customer/CustomerInfo.vue');
    } else {
        loadContents('/Login.vue');
    }
}

function loadContents(_path, _param) {
    if ($utils.isNotNull(_param)) {
        mainParam.value = _param;
    } else {
        mainParam.value = {};
    }

    if (_path !== useCustomerStore().pageHist[useCustomerStore().pageHist.length - 1]) {
        useCustomerStore().pageHist.push(_path);
    }

    if (props.param.type !== 'LOGIN' && props.param.type !== 'JOIN') {
        $log.debug('Main.vue', '@/views' + _path, mainParam.value)

        mainComponent.value = markRaw(defineAsyncComponent(() => import('@/views' + _path)));
    } else {
        mainComponent.value = markRaw(defineAsyncComponent(() => import('@/views' + _path)));
    }
}

function back() {
    let _currentPath = useCustomerStore().pageHist.pop(); // 이력에서 현재 페이지 제거
    let _prevPath = useCustomerStore().pageHist.pop();

    if ($utils.isNotNull(_currentPath)) {
        if ($utils.isNotNull(_prevPath)) {
            mainComponent.value = markRaw(defineAsyncComponent(() => import('@/views' + _prevPath)));
        } else {
            mainComponent.value = markRaw(defineAsyncComponent(() => import('@/views' + _currentPath)));
        }
    }
}

function home() {
    loadContents('/loan/LoanMain.vue');
}
</script>
