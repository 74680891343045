<template>
</template>
<script setup>
import {defineProps, onMounted} from "vue";
import {$client, $dialog, $kcb_cert, $utils} from "@/assets/js/utils";
import {useCustomerStore} from "@/stores/customerStore";

const props = defineProps({
    param: Object
});

onMounted(() => {
    init();
})

async function init() {
    let mdlTkn = props.param.mdl_tkn;

    await $kcb_cert.result(mdlTkn, function(response) {
        nextProcess(response);
    }, function(error) {
        if (sessionStorage.getItem('kcb_cert_call') === 'CREDIT') {
            sessionStorage.setItem('kcb_cert_call', 'CREDIT_ERROR');

            window.location.href = '/';
        } else if (sessionStorage.getItem('kcb_cert_call') === 'MORTGAGE') {
            sessionStorage.setItem('kcb_cert_call', 'MORTGAGE_ERROR');

            window.location.href = '/';
        } else if (sessionStorage.getItem('kcb_cert_call') === 'JEONSE') {
            sessionStorage.setItem('kcb_cert_call', 'JEONSE_ERROR');

            window.location.href = '/';
        } else {
            sessionStorage.removeItem('kcb_cert_call')

            window.location.href = '/login';
        }
    });
}

async function nextProcess(_response) {
    sessionStorage.setItem('tx_seq_no', _response.tx_seq_no);

    // 회원가입에서 호출한 경우
    if (sessionStorage.getItem('kcb_cert_call') === 'JOIN') {
        let param = {
            tx_seq_no: _response.tx_seq_no
        };

        // 가입 여부 체크
        await $client.post('/svc/join/check', param, function (response) {
            sessionStorage.setItem('rslt_name', _response.rslt_name);

            window.location.href = '/join';
        }, function(error) {
            // 카카오 로그인 -> 카카오 아이디 없음 -> 회원 가입 페이지
            if ($utils.isNotBlank(sessionStorage.getItem('kakao_id'))) {
                $dialog.confirm('카카오 로그인을 등록하시겠습니까?', function () {
                    let kakaoParam = {
                        tx_seq_no: _response.tx_seq_no
                        , kakao_id: sessionStorage.getItem('kakao_id')
                        , kakao_email: sessionStorage.getItem('kakao_email')
                    };

                    $client.post('/svc/kakao/register', kakaoParam, function () {
                        $dialog.alert('등록하였습니다.', function () {
                            sessionStorage.removeItem('kcb_cert_call');
                            sessionStorage.removeItem('kakao_id');
                            sessionStorage.removeItem('kakao_email');

                            window.location.href = '/login';
                        });
                    });
                }, function() {
                    sessionStorage.removeItem('kcb_cert_call');
                    sessionStorage.removeItem('kakao_id');
                    sessionStorage.removeItem('kakao_email');

                    window.location.href = '/login';
                });
            } else {
                sessionStorage.removeItem('kcb_cert_call');

                window.location.href = '/login';
            }
        });
    } else if (sessionStorage.getItem('kcb_cert_call') === 'FIND') {
        let param = {
            tx_seq_no: _response.tx_seq_no
        };

        // 사용자 정보 조회
        await $client.post('/svc/find/customer', param, function (response) {
            sessionStorage.setItem('customer_id', response.customer_id);

            if ($utils.isNotBlank(response.kakao_email)) {
                sessionStorage.setItem('kakao_email', response.kakao_email);
            }

            window.location.href = '/';
        });
    } else if (sessionStorage.getItem('kcb_cert_call') === 'CHG') {
        let param = {
            tx_seq_no: _response.tx_seq_no
        };

        // 휴대폰번호 변경
        await $client.post('/svc/customer/hpno/modify', param, function (response) {
            $dialog.alert("변경되었습니다.", function () {
                window.location.href = '/';
            });
        })

    } else {
        window.location.href = '/';
    }
}
</script>
