import {createRouter, createWebHistory } from 'vue-router';
import {useCustomerStore} from "@/stores/customerStore";

import {$client, $utils} from "@/assets/js/utils";

import MainView from '@/views/Main.vue';
import KcbCertView from '@/views/common/KcbCert.vue';
import KakaoLoginView from "@/views/common/KakaoLogin.vue";

let param = {};

const checkLogin = async () => {
    if ($utils.isNotBlank(document.referrer)
            && !document.referrer.startsWith($utils.getUrl())) {
        sessionStorage.setItem('referrer', document.referrer);

        await $client.post('/svc/init?referer=' + document.referrer)
    }

    let customerStore = useCustomerStore();

    if ($utils.isBlank(customerStore.accessToken) || $utils.isNull(customerStore.customerInfo)) {
        param = { type: 'MAIN' };
    } else {
        param = { type: 'MAIN' };
    }

    return MainView;
}

const kakaoLogin = async () => {
    // code 값으로 access_token 을 가져옴
    const urlParams = new URLSearchParams(window.location.search);

    param = { code: urlParams.get('code') };

    return KakaoLoginView;
}

// 본인 인증 결과 조회 후 처리
async function kcbCert() {
    const urlParams = new URLSearchParams(window.location.search);

    param = { mdl_tkn: urlParams.get('mdl_tkn') };

    return KcbCertView;
}

async function partnerSave() {
    const urlParams = new URLSearchParams(window.location.search);

    sessionStorage.setItem('partner_code', urlParams.get('code'));

    param = { type: 'MAIN' };

    return MainView;
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'main',
            component: async () => {
                return await checkLogin();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/login',
            name: 'login',
            component: MainView,
            props: () => {
                return { param: { type: 'LOGIN' } }
            }
        },
        {
            path: '/join',
            name: 'join',
            component: MainView,
            props: () => {
                return { param: { type: 'JOIN' } }
            }
        },
        {
            path: '/kakao/login',
            name: 'kakao login',
            component: async () => {
                return await kakaoLogin();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/kcb/cert',
            name: 'kcb cert',
            component: async () => {
                return await kcbCert();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/partner',
            name: 'partner',
            component: async () => {
                return await partnerSave();
            },
            props: () => {
                return { param: param }
            }
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/'
        }
    ]
})

export default router
