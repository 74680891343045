import { defineStore } from "pinia";
import dayjs from "dayjs";
import { $utils } from "@/assets/js/utils";

export const useCodeStore = defineStore('code_store', {
    state: () => ({
        timestamp: '',
        codeInfo: null
    }),
    getters: {},
    actions: {
        getCodeInfo(_val) {
            if ($utils.isBlank(_val)) {
                return this.codeInfo;
            } else {
                for (let idx in this.codeInfo) {
                    if (this.codeInfo[idx].cmn_code === _val) {
                        return this.codeInfo[idx]._children;
                    }
                }
            }

            return null;
        },

        setCodeInfo(_codeInfo) {
            this.timestamp = dayjs().format('YYYY-MM-DDTHH:mm:ss');
            this.codeInfo = _codeInfo;
        }
    },
    persist: {
        storage: sessionStorage
    }
});
