
import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/material-icons-round'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import LoadingBar from 'quasar/src/plugins/loading-bar/LoadingBar.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;
import lang_ko from "quasar/lang/ko-KR";

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        loadingBar: { position: 'top', size: '4px' }
    }
    , plugins: {
        Dialog
        , Loading
        , LoadingBar
        , Notify
    }
    , iconSet: iconSet
    , lang: lang_ko
}
