import { createApp } from 'vue'
import App from '@/App.vue'

// Router
import router from '@/router'

// Pinia
import { createPinia } from 'pinia'
import persistedstate from 'pinia-plugin-persistedstate'

// Quasar
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from '@/quasar-user-options'

// Component
import Footer from '@/components/Footer.vue';

const app = createApp(App)

app.use(router)
app.use(createPinia().use(persistedstate))
app.use(Quasar, quasarUserOptions)
app.component('footer-component', Footer)

app.mount('#app')

window.Kakao.init("7d187b8f9838ad93c0ac67dc33f977d7");
window.Kakao.isInitialized();
